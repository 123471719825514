<template>
	<div class="wrapper">
		<video preload="auto" playsinline autoplay loop muted class="video">
			<source src="../../assets/background/bg-video.mp4" type="video/mp4" />
		</video>
		<div class="header">
			<slot name="header" />
		</div>
		<div class="container-content">
			<slot name="content" />
		</div>
	</div>
</template>

<script lang="ts" setup></script>

<style scoped lang="css">
.wrapper {
	@apply flex h-screen justify-center items-center relative;
}

.header {
	@apply absolute z-20 top-10 left-0 pl-4;
}

.video {
	@apply absolute z-10 w-full h-full object-cover;
}

.container-content {
	@apply relative z-20 bg-white px-6 py-8 sm:px-12 sm:py-14 shadow-md h-auto flex flex-col justify-center items-center space-y-4 w-[90%] sm:w-[600px];
}
</style>
